import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {LocaleContext} from "./layout";

const useTranslationsCat = () => {
    // Grab the locale (passed through context) from the Context Provider
    const {locale} = React.useContext(LocaleContext);
    // Query the JSON files in <rootDir>/i18n/translations
    const {rawData} = useStaticQuery(query);

    // Simplify the response from GraphQL
    const simplified = rawData.edges.map((item) => {
        return {
            name: item.node.name,
            translations: item.node.translations,
        };
    });

    // Only return translations for the current locale
    const {translations} = simplified.filter((lang) => lang.name === locale)[0];

    return translations;
};

export default useTranslationsCat;

const query = graphql`
	query useTranslationsCat {
		rawData: allFile(filter: { sourceInstanceName: { eq: "translationsCat" } }) {
			edges {
				node {
					name
					translations: childTranslationsCatJson {
                        thermometers
                        digitalThermometers
                        dataloggersTemperature
                        analogThermometers
                        thermographs
                        remoteSensingDialThermometers
                        rsdOrder
                        rsdSp
                        rsdWl
                        rsdFl
                        rsdSt
                        rsdWp
                        wpWl
                        wpFl
                        wpS
                        wpT
                        rsdO
                        oWl
                        oFl
                        oS
                        oT
                        rsdEc
                        ecWl
                        ecFl
                        ecSt
                        ecWp
                        ecRu
                        rsdRc
                        rsdDc
                        bimetalThermometers
                        bmOrder
                        bmSp
                        bmT
                        bmS
                        bmF
                        bmR
                        bmEc
                        bmWp
                        bmDc
                        standardGlassThermometers
                        glassThermometers
                        thermocouples
                        resistanceTemperatureDetectors
                        thermohygrometers
                        digitalThermohygrometers
                        dataloggersTemperatureHumidity
                        transmitters
                        dialThermohygrometers
                        thermohygrographs
                        psychrometers
                        anemometers
                        heatStressMonitors
                        infraredThermometers
                        barometers
                        digitalBarometer
                        dialBarometers
                        barograph
                        recorders
                        indicators
                        weatherInstruments
                        windSpeedDirectionIndicator
                        anemometersWeatherInstruments
                        rainGauge
                        barometersWeatherInstruments
                        loggers
                        instrumentScreens
                        moistureMeters
                        soilPhMetersSaltMetersCo2MetersOtheres
                        refractometers
                        phMeters
                        timers
                        timersSecondLayer
                        stopwatches
                        sandglasses
                        optionalProbes
                        forSkL751
                        forSkL753
                        forSkL754
                        forSkL700rT
                        forSkL700rTh
                        forSkL200TIISeries
                        forSkL200th2aSeries
                        forSkL400tSk1110Sk1120Sk7000PRTII
                        forSk1260
                        forSk1250MCIIISk1250MCIIIa
                        forSk270wpSeries
                        forSk250wp2Series
                        forSk100wp
                        forSk810pt
                        forSk110trhB
                        forSkRhcSeries
                        forSkM460TSkM350T
                        forSkM350rT
                        forSkM350rTrh
                        forSk610ph2
                        forSk660phSeries
                        forSk620ph2Sk650ph
                        forSk620ph
                        forThermocouple
                        chartsAndPens
                        forSigma2Thermohygrographs
                        forAurora903Thermohygrograph
                        forSigma2Thermograph
                        forSigma2Hygrograph
                        forSigma2Barograph
                        forSigma2RemoteThermographs
                        forMiniCubeThermohygrograph
                        forMiniStarThermohygrograph
                        forMiniAlphaThermohygrograph
                        forEventRecorder
                        forLmmcTemperatureRecorder
                        forVariousRecorders
                        cartridgePens
                        otherAccessories
                        seriesProducts
                        seriesSk270wp
                        seriesSk1260
                        seriesSk110trhb
                        seriesSk630ph
                        seriesSk660ph
                        seriesSk960a
                        seriesBms90s
                        seriesBmt90s
                        seriesBmt75s
                        seriesBms75p
                        seriesBmt75p
                        seriesBms100p
                        seriesBmt100p
                        seriesProtectorPipeForBimetal
                        seriesProtectorPipeForBimetalBs
                        seriesProtectorPipeForRemoteSensing
                        seriesLb100s150s
                        seriesVb100p
                        seriesYoungAnemometer
                        seriesHandheldAnemometer
                        seriesWvat6d0
                        seriesWat6d0
                        seriesRa001
                        seriesIndicatorTemp
                        seriesIndicatorHum
                        seriesSkrsd10
                        seriesSkrm10c
                        seriesSkrm18j
                        seriesSurfaceBimetal
                        seriesPocketBimetal
                        seriesAsphaltBimetal
                        seriesMiluno
                        seriesMilunotz
                        seriesWoodenzBase
                        seriesWoodenzBase2
                        seriesSoilAir
                        seriesCheckermate
                        seriesSigma2HvrCharts
                        seriesSigmaminiCharts
                        seriesSigma2OtherAccessories
                        seriesSkrhgOption
                        seriesCalibrationSolution
                        seriesSensorFilters
                        seriesTm24p
                        seriesBabymate
                        traceabilityProducts
                        discontinuedProducts
					}
				}
			}
		}
	}
`;
